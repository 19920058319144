.container-fluid {
    padding: 0px 60px !important;
}

.navv {
    background-color: #dff1ff;
}

.navbar {
    /* display: flex;
    justify-content: space-between;
    align-items: center; */

}

.navbar .container {
    justify-content: flex-end;
}

.navbar .hideLinks,
.navbar .showLinks {
    display: inline-flex;
    align-items: center;
    list-style-type: none;
    gap: 16px;
    /* margin-bottom: 0.5rem; */
    padding: 0px;
    margin: 0px;
}

.nav-item,
.navbar ul>li {
    font-weight: bold;
}

.nav-link {
    padding: 0;
}

.navbar ul>li>span,
.navbar ul>li>a {
    text-decoration: none;
    color: black;
    /* font-weight: bold; */
    padding-bottom: 5px;
    text-transform: uppercase;

}

.navbar ul>li a:hover {
    color: var(--primary-clr);
}

.navbar ul>li>span.active,
.navbar ul>li>a.active {
    color: var(--primary-clr);
    border-bottom: 3px solid var(--primary-clr);
    font-weight: normal;
}

.navbar ul>li>button.appoinment-btn:active {
    color: var(--third-clr);
    background-color: var(--primary-clr);
}

.navbar ul>li>button.appoinment-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: var(--btn-radius);
    border: var(--btn-border) var(--primary-clr);
    background: transparent;
    color: var(--primary-clr);
    font-weight: bold;

}

.navLinks_div .menu-icon {
    display: none;
}


.dropdown-menu {
    width: 380px;
    flex-direction: column;
    background-color: #dff1ff;
    gap: 0px;
}

.dropdown-menu.show {
    display: flex;
    flex-direction: column;
}

.dropdown-menu a {
    color: black;
    /* padding-left: 20px; */
    text-decoration: none;
}

.dropdown-menu a.active {
    color: var(--primary-clr);
}

.dropdown:hover .main_menu {
    display: flex;
    /* padding: 6px 20px; */
}

.main_menu {
    list-style: none;
    position: absolute !important;
    z-index: 1000;
    inset: 0px auto auto 0px;
    transform: translate(-11px, 25px);
    padding: 0;
    border-radius: 0;

}

.main_menu li {
    padding: 4px 20px;
}

/* .dropdown-menu li {
    position: relative;
    padding: 4px 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.268);
    } */

.dropdown-menu .active {
    background-color: transparent !important;
}

.drop_toggle {
    color: black;
    font-size: 20px;
}


/* new style */

.dropdown-menu .dropdown-submenu {
    display: none;
    position: absolute;
    left: 100%;
    top: -1px;
    width: 350px;
    padding: 0;
    border-radius: 0;
}

.dropdown-menu .dropdown-submenu-left {
    right: 100%;
    left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
    display: flex;
}

.dropdown-menu>li:hover .submenuDiv .arrow_icn {
    rotate: 90deg;
    transition: all 0.5s;
}

/* new style */

.nav-tabs {
    border: none;
}

.modal-header {
    border: none;

}

.nav-fill .nav-item {
    width: 50%;
}

.m_body .nav-fill .nav-item .nav-link {
    padding: 20px 0px;
    border-radius: 0px;
    border: none;
    color: var(--primary-clr);
    font-weight: bold;
}

.m_body form {
    display: grid;
    gap: 1rem;
}

.login_m .modal-content {
    background: transparent;
    border: none;
}

.m_head {
    width: 10%;
    position: relative;
    left: 90%;
    background-color: var(--third-clr);
    padding: 15px 17px 15px 0px
}

.m_head button:focus {
    outline: none;
    box-shadow: none;
}


.m_body {
    background-color: var(--third-clr);
    border-radius: 5px 0px 5px 5px;
}

.m_body .nav-tabs .nav-link.active {
    background-color: var(--primary-clr);
    color: var(--third-clr);
    border-radius: inherit;
}

.m_body .tab-content {
    padding: 0px 50px;
}

.m_body label {
    font-size: 16px;
    font-weight: bold;
    color: var(--primary-clr);

}

input::placeholder {
    font-size: 14px;
}

.m_body input {
    border: 1px solid #bce1ff;
    border-radius: 0px;
    padding: 10px;
    background-color: #dff1ff;
    outline: none;
    width: 100%;
    padding-right: 45px;
    box-shadow: none !important;
}

.loginBtn:hover,
.loginBtn {
    background-color: var(--primary-clr);
    color: var(--third-clr);
    border: none;
    padding: 10px 30px;
    border-radius: 0px;
    font-weight: bold
}

.eyeButton:active,
.eyeButton:hover,
.eyeButton {
    position: absolute;
    right: 0;
    top: 45%;
    background: transparent !important;
    border: none;
}

.eyeButton .eye_ {
    font-size: 20px;
    color: var(--primary-clr);
}

.nav-pills .nav-link.active {
    background-color: var(--primary-clr);
    color: var(--third-clr);
}

.nav-pills .nav-link {
    padding: 10px;
    color: var(--primary-clr);
    border-radius: 0px;
}

.left_n {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.tab_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modelSpinner {
    color: var(--primary-clr);
    position: absolute;
    top: 50%;
    left: 48%;
    z-index: 1000;

}

.rdt_TableCell,
.rdt_TableCol {
    padding: 0px;
    margin: 0px 20px;
}

.exist_model h3 {
    font-size: 1.45rem;
}

.exist_btn {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding: 20px;
}

.exist_btn button:active,
.exist_btn button:hover,
.exist_btn button {
    padding: 6px 10px;
}


.btn-secondary:hover,
.btn-secondary,
._close_btn{
    background-color: #6c757d;
    border-color: #6c757d;
    font-weight: bold;
}

.btn-primary:hover,
.btn-primary{
    background-color: var(--primary-clr);
    border-color: var(--primary-clr);
    font-weight: bold;
}

.exist_btn ._cancel,
.btn-danger,
.btn-danger:hover,
._cancel{
    background-color: #dc3545;
    border-color: #dc3545;
    font-weight: bold;
}


.navbar .showLinks,
.navbar .hideLinks {
    gap: 40px;
}

@media only screen and (max-width : 1216px) {
    .container-fluid {
        padding: 0px 35px !important;
    }

    div.logo a img{
        width: 200px;
    }
    
    .navbar .showLinks, .navbar .hideLinks{
        gap: 19px;
    }
    
}


@media only screen and (max-width : 991.98px) {

    .container-fluid {
        padding: 0px 40px !important;
    }

    .navLinks_div .menu-icon {
        display: block;
    }

    .navbar .hideLinks {
        display: none;
    }

    .navbar .showLinks {
        display: block;
        position: absolute;
        display: flex;
        flex-direction: column;
        /* right: 50%; */
        background-color: #dff1ff;
        transform: translate(-50%, 8%);
        left: 49%;
        padding: 20px;
        width: 100%;
        text-align: center;
        z-index: 1000;
    }

    .logo img {
        width: 100px;
    }

}

@media only screen and (max-width : 767.98px) {
    .container-fluid {
        padding: 0px 30px !important;
    }
}


@keyframes fadeIn {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}


/* new style */

.navbar {
    padding: 20px 0px;
}


ul>li:last-child button.appoinment-btn {
    border-radius: var(--btn-radius);
    padding: var(--btn-padding);
    border: var(--btn-border) var(--primary-clr);
    font-size: var(--third-h);

}

ul>li:last-child button.appoinment-btn:hover {
    background-color: var(--primary-clr);
    color: var(--third-clr);
    border-color: var(--primary-clr);
    font-size: var(--third-h);
}

.nav-item,
.navbar ul>li,
.navbar ul>li a {
    font-weight: normal;
    font-size: var(--third-h);
    text-transform: uppercase;
}


.login_m .modal-content {
    /* background-color: var(--third-clr); */
}

.modal-title {
    font-size: var(--secondary-h);
}


.submenuDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.dropdown-submenu li a.active {
    border-bottom: none !important;
}

.f_pass {
    float: right;
    cursor: pointer;
    font-size: 14px;
    color: var(--primary-clr);
}

/* new style */