* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: 'Work Sans', sans-serif !important;
}



:root {
  --primary-clr: #146FB7;
  --secondary-clr: #DBA11C;
  --btn-border: 2px solid;
  --third-clr: #fff;
  --btn-weight: bold;
  --btn-padding: 8px 20px;
  --primary-h: 1.75rem;
  --secondary-h: 1.25rem;
  --third-h: 16px;
  --padding-equal: 3rem;
  --banner-h: 500px;
  --btn-radius: 0px;
  --gray-clr: #545050;
  --black-clr: black;
  --m-padding-equal: 2rem;
  --m-third-h: 14px;

}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
button,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
nav,
object,
ol,
p,
pre,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
title,
tr,
tt,
u,
ul,
var {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  scroll-behavior: smooth;
}

/* p{
  line-height: 2rem;
} */

.btn {
  border-radius: var(--btn-radius);
  border: var(--btn-border);
  padding: var(--btn-padding);
  font-weight: var(--btn-weight);
  font-size: var(--third-h);
  text-transform: uppercase;
  /* transition: all 0.5s; */
  background: transparent
}

.btn a {
  font-size: var(--third-h);
  font-weight: var(--btn-weight);

}

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

@media only screen and (min-width : 1700px) {
  .fix_width {
    width: 1500px;
    margin: auto;
  }

}

/* webpack --mode=production  */
/* new design */


/* new design */


.banner {
  /* background-position: center; */
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
  height: 100%;
  background-image: linear-gradient(200deg, #BEE1FF, #DFF1FF, #FFFFFF 70%)
}


.banner img.full_bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner img.full_bg::before {
  content: '';
  background-image: linear-gradient(200deg, #BEE1FF, #DFF1FF, #FFFFFF 70%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

/* .our_profationals, */
/* .contact_div, */
/* section ._success_d, */
section.blogs_s .our_blog,
section.our_profationals,
.contact_us,
section.about_image-s,
.our_clients,
.our_process,
section.our_blog-s,
/* section.our_clients-s, */
section.our_work-s,
section.bookUsNow-s,
/* section.our_process-s, */
section.service-s,
section.why_blueCleaning-s,
section.book_now-s,
section.company-s {
  padding: var(--padding-equal) 0px;
  /* height: 100vh; */
}


.our_clients-s,
.our_process-s {
  margin: var(--padding-equal) 0px;
}

/* .our_process, */
/* section.our_work-s{
  padding-top: var(--padding-equal)
} */

/* .fix_width, */

section.content-s,
.our_clients .marque-s {
  padding-bottom: var(--padding-equal)
}

section.blogs_s .our_blog {
  margin-top: var(--padding-equal);
}

.content_div .strar-btn,
.banner .call-btn {
  display: flex;
  gap: 10px;
  align-items: center;
  border-radius: var(--btn-radius);
  border: var(--btn-border) var(--primary-clr);
  background: transparent;
  color: var(--primary-clr);
  font-weight: var(--btn-weight);
  font-size: var(--third-h);
  padding: var(--btn-padding);
}

.content_div .strar-btn {
  font-size: 14px;
  border: 1px solid var(--primary-clr);

}

.strar-btn a {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--primary-clr);
}



.banner .call-btn>img {
  width: 25px;
}

.content_div {
  position: relative;
  /* top: 40px; */
  /* left: 60px; */
  font-weight: bold;
  width: 50%;
}

.content_div h1 {
  font-size: 3rem;
  color: var(--primary-clr);
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.content_div .starDiv {
  color: #DBA11C;
  display: flex;
  align-items: center;
  gap: 2px;
}

.content_div .starDiv span {
  font-size: 16px;
}

.serviceImgDiv {
  text-align: center;
  position: relative;
  /* top: 40px; */
}

.serviceImgDiv img {
  width: 100%;
  object-fit: cover;
  max-height: 400px;
}

.bookAppoinment_section {
  padding-bottom: var(--padding-equal);
}

.bookAppoinment_section div {
  background-color: rgba(0, 0, 0, 0.524);
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 98px;
  border-radius: var(--btn-border);
  align-items: center;
  gap: 30px;
  padding: 30px;
  width: 75%;
  justify-content: center;

}

.bookAppoinment_section .form-select {
  width: 33.3%;
  border-radius: var(--btn-radius);
  border: var(--btn-border) var(--third-clr);
  padding: var(--btn-padding);
  font-size: var(--third-h);

}

.bookAppoinment_section .dropdown button:hover,
.bookAppoinment_section .dropdown button:active,
.bookAppoinment_section .dropdown button {
  background-color: var(--third-clr);
  color: black;
}


.bookAppoinment_section .dropdown .dropdown-toggle::after {
  border: none;
}

.bookAppoinment_section option {
  text-transform: uppercase;
}


.appoinment-btn:hover,
.appoinment-btn:active,
.appoinment-btn {
  /* display: flex;
  gap: 10px;
  align-items: center;
  color: var(--third-clr);
  background-color: #DBA11C;
  border-color: #DBA11C; */
}

.our_company_div {
  /* padding-top: var(--padding-equal); */
  /* padding-bottom: var(--padding-equal); */
}

.our_company_div h3 {
  /* margin-bottom: 20px; */
}

.our_company_div p {
  line-height: 2rem;
}

.our_works,
.our_blog,
.our_work,
.our_process,
.why_blueCleaning,
.our-company {
  /* min-height: var(--banner-h); */
  height: auto;
  /* padding: var(--padding-equal) 0px; */
  /* margin-top: 7rem; */
}

.our-company {
  width: 90%;
}


.our-company ._heading {
  width: 75%;
}

.our-company h3 {
  margin-bottom: 20px;
}

.our-company p {
  line-height: 2rem;
}

.our-company:nth-child(1) {
  /* width: 75%; */
}

.our-company .description {
  line-height: 2rem;
}


.our_works h5,
.our_profationals h5,
.sub_services h5,
.services_section h5,
.our_blog h5,
.our_work h5,
.our_process h5,
.why_blueCleaning h5,
.our-company h5 {
  color: var(--black-clr);
  text-align: center;
  position: relative;
  /* today */
  display: flex;
  align-items: center;

}

.our_works h5::before,
.our_profationals h5::before,
.sub_services h5::before,
.services_section h5::before,
section.blogs_s .our_blog h5::before,
.our_work h5::before,
.our_process h5::before,
.why_blueCleaning h5::before,
.our-company h5:before {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background: var(--primary-clr);
  left: 0;
  top: 50%;
  /* today */
  /* position: absolute; */
}

.our_works h5::after,
.our_profationals h5::after,
.sub_services h5::after,
.services_section h5::after,
section.blogs_s .our_blog h5::after,
.our_work h5::after,
.our_process h5::after,
.why_blueCleaning h5::after,
.our-company h5::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background: var(--primary-clr);
  right: 0;
  top: 50%;
  /* today */
  /* position: absolute; */
}

.our_blog h5::before,
.our_blog h5::after {
  background: var(--third-clr);
}

.our-company button {
  font-weight: bold;
}


.our_clients h3,
.our_services h3,
.our_clients,
.our_services {
  color: var(--third-clr);
}

.our_services {
  /* height: 630px; */
}


.our_clients h5,
.our_services h5 {
  color: #DBA11C;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
}

.our_clients h5::before,
.our_services h5::before {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background: var(--third-clr);
  left: 0;
  top: 50%;
  /* position: absolute; */
}

.our_clients h5::after,
.our_services h5::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background: var(--third-clr);
  right: 0;
  top: 50%;
  /* position: absolute; */
}


.why_blueCleaning .nav-link {
  padding: 30px 0px;
}

.why_blueCleaning .nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  color: var(--gray-clr);
  font-weight: bold;
}

.why_blueCleaning .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #dff1ff;
  color: var(--primary-clr);
}

.why_blueCleaning .nav-tabs .nav-link {
  border: none;
  border-radius: 0px;
  font-size: var(--secondary-h);
}

.why_blueCleaning .nav-tabs {
  margin: 0px 40px;
  border: none;
}

.tab_content {
  background-image: linear-gradient(#e2e9ef, var(--third-clr));
  /* height: 400px; */
  padding: 30px 40px;
  padding-bottom: 0px;
  margin: 0px;
}

.left_div {
  display: flex;
  flex-direction: column;
  gap: 40px;
  /* padding: 0px 30px; */
  padding-left: 30px;
  /* justify-content: space-around; */
}

.left_div .left_content {
  padding: 10px 20px;
  display: flex;
  background-color: var(--third-clr);
  box-shadow: 3px 3px 10px #146FB761;
  position: relative;
}

.left_div .left_content>img {
  position: absolute;
  left: -30px;
  /* top: 23%; */
}

.left_content .left_des {
  margin-left: 40px;
  /* font-weight: bold; */
  /* max-width: 250px; */
}

.left_content .left_des span {
  font-size: var(--third-h);
  font-weight: bold;
  color: var(--gray-clr);
}

.left_content .left_des p {
  font-size: var(--third-h);
  margin: 0px;
}

.services_img {
  display: flex;
  justify-content: space-evenly;

}

.img_div {
  padding-bottom: 7rem;
  position: relative;
}

.img_div>img {
  width: 100%;
}

.img-subDiv {
  position: absolute;
  bottom: 0%;
  display: flex;
  align-items: end;
  width: 100%;
  text-align: center;
}

.img-subDiv span {
  background-color: var(--third-clr)fff;
  color: black;
  padding: 5px 0rem;
  width: 75%;
  font-weight: bold;

}


.img-subDiv .iconImg {
  background-color: #DBA11C;
}

.center_imgDiv img {
  width: 100%;
}


.our_process_imgDiv {
  /* padding-bottom: 4rem; */
  position: relative;
}

.our_process_imgDiv img {
  z-index: -1;
}

.our_process_imgDiv>.img1 {
  position: absolute;
  top: -150px;
  /* left: -200px; */
}

.our_process_imgDiv>.img2 {
  position: absolute;
  /* top: -170px; */
  bottom: 30px;
  right: 0px;
}

.our_process_imgDiv>.img3 {
  position: absolute;
  left: 50%;
  top: 100px;
}

.our_process_imgDiv>.img4 {
  position: absolute;
  left: 7%;
  top: -40px;
}

.our_process_imgDiv>.img5 {
  position: absolute;
  right: 12%;
  top: -60px;
}

.our_process_div {
  position: relative;
  /* display: flex;
  justify-content: center; */
  /* gap: 40px; */
  width: 100%;
  /* bottom: 70px; */
  display: flex;
  align-items: baseline;
  /* align-items: center; */
  text-align: center;
}

.our_process_div h3 {
  color: black;
}

.our_process_content {
  /* max-width: 400px; */
}

.our_process_content img {
  margin-bottom: 40px;
}

.our_process .steps_div h3 {
  font-size: var(--secondary-h);
}


.bookUsNow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--third-clr);
}

.bookUsNow .btn_div .call-btn:hover {
  color: var(--primary-clr);
  background-color: var(--third-clr);
}

.bookUsNow h2 {
  /* font-weight: bold;
  font-size: var(--primary-h);
  padding: var(--btn-padding); */
}

.bookUsNow .btn_div {
  color: var(--third-clr);
  display: flex;
  gap: 40px;
  margin-top: 20px;
}

.bookUsNow .btn_div .call-btn,
.form-d .call-btn {
  border: 2px solid var(--third-clr);
  background: transparent;
  color: var(--third-clr);
  border-radius: 0px;
  transition: all 0.5s;
  font-weight: bold;
  padding: 8px 20px;
}

/* .bookUsNow .btn_div .call-btn {
  color: var(--third-clr);
  border-color: var(--third-clr);
} */

.mySwiper {
  /* height: 240px; */
}

.mySwiper .SliderImgDiv {
  width: fit-content;
  margin: auto;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 41% !important;
  top: 85% !important;
  background-color: var(--third-clr);
  padding: 30px !important;
  border-radius: 50%;
  width: 25px !important;

}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 41% !important;
  top: 85% !important;
  background-color: var(--third-clr);
  padding: 30px !important;
  border-radius: 50%;

}

.swiper-wrapper {
  /* padding: 0px 29px; */
}

.clientsSwiper .swiper-wrapper {
  /* height: 500px; */
  margin-bottom: 3rem;
  color: #fff;
}

.clientsSwiper .swiper-pagination-bullet {
  background-color: #fff !important;
}

.workSwiper {
  height: 300px;
}

.workSwiper img {
  width: 275px;
  height: 200px;
}

.workSwiper figure {
  height: 200px;
  background: #156FBB;
  position: relative;
}

.workSwiper figure img {
  opacity: 1;
}

.workSwiper figure:hover img {
  opacity: 0.4;
}

.after-figure h3,
.before-figure h3 {
  display: none;
}

.after-figure:hover h3,
.before-figure:hover h3 {
  display: block;
  position: absolute;
  top: 50%;
  color: var(--third-clr);
  left: 50%;
  transform: translate(-50%, -50%);
}



.workSwiper2 {
  /* height: 340px; */
}

.blogSwiper {
  height: 350px;
}

.serviceSwiper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5rem;
  /* height: 530px; */
}

.serviceSwiper .card {
  border: 0px;
  border-radius: 0px;
  width: fit-content;
  margin: auto;
}

.serviceSwiper .card {
  min-width: 329px;
  width: 100%;
  height: auto;

}


.client_div {
  /* background-color: var(--third-clr);
   */
  background-color: #fff;
  border-radius: 10px;
  color: var(--gray-clr);
  padding: 20px;
  min-height: 320px;
  height: 380px;
  position: relative;
  /* width: 480px; */
  border-radius: 0px;
}


.client_div p {
  font-size: var(--third-h);
  color: var(--black-clr);
}

.client_div .comma {
  position: absolute;
  left: 0px;
  top: 0;
}

.clients_review {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
}

.client_info {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 10px;
  right: 20px;
  text-align: center;
}

.client_info span {
  font-weight: bold;
}

.client_info p {
  margin: 0px;
}

.client_info .star-d {
  color: #DBA11C;
  text-align: center;
}

.bg_imgDiv {
  position: relative;
}

.bg_imgDiv .img1 {
  position: absolute;
  /* left: -100px; */
  top: -180px;
  z-index: -1;

}

.bg_imgDiv .img2 {
  position: absolute;
  right: 0px;
  top: -175px;
  z-index: -1;
}

/* gallery */
.our_works {
  /* height: 700px; */
  z-index: 1;
  /* padding-bottom: 20px; */
}


.our_works .nav-fill .nav-item .nav-link,
.our_works .nav-justified .nav-item .nav-link {
  padding: 16px 0px;
  border-radius: 0px;
  border: 1px solid var(--primary-clr)21;
  color: var(--primary-clr);
}

.our_works .nav-tabs .nav-item.show .nav-link,
.our_works .nav-tabs .nav-link.active:hover,
.our_works .nav-tabs .nav-item.show .nav-link,
.our_works .nav-tabs .nav-link.active {
  color: var(--third-clr);
  background-color: var(--primary-clr);
}

.our_works .nav-tabs {
  display: flex;
  width: 75%;
  justify-content: center;
  gap: 40px;
  border: none;
}

.swiper-slide {
  text-align: center;
}

.our_story p {
  line-height: 2rem;
}

.contact_us {
  display: flex;
  justify-content: space-between;
  gap: 10px;

}

.contact_us .card_div {
  width: 100%;
  height: 250px;
}

.card_div .card {
  border: 1px solid var(--primary-clr);
  width: 100%;
  height: 100%;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
  font-weight: bold;
  padding: 30px;
}

.card_div .card img {
  -webkit-transition: 0.5s;
  -webkit-transform-style: preserve-3d;
  position: relative;
}

.card_div .card:hover img {
  /* -webkit-transform: rotateY(360deg); */
  -webkit-transform: translateY(-10px);
}

section.blogs_s .our_blog h3,
.contact_us .card_div a {
  color: var(--primary-clr);
}

.contact_div {
  /* padding-bottom: 40px; */
  /* height: 500px; */
}

.contact_div label {
  font-weight: 500;
  color: black;
}

.multi_services .searchWrapper {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  border: 1px solid #bce1ff;
  border-radius: 0px;
  background-color: #dff1ff;
  outline: none;
  width: 100%;
}

.multi_services .optionListContainer .optionContainer {
  display: grid;
  gap: 1px;
}

/* scroll */
.multi_services .searchWrapper::-webkit-scrollbar-track {
  background-color: #bce1ff;
}

.multi_services .searchWrapper::-webkit-scrollbar {
  height: 5px;
  background-color: #bce1ff;
}

.multi_services .searchWrapper::-webkit-scrollbar-thumb {
  background-color: var(--primary-clr);
  border-radius: 5px;
}


.comman_form label {
  font-weight: bold;
}

.comman_form .form_div {
  /* padding: 0px 45px; */
}


.form_div select,
.comman_form input,
.comman_form textarea,
.contact_div option,
.contact_div select,
.contact_div input,
.contact_div textarea {
  border: 1px solid #bce1ff;
  border-radius: 0px;
  padding: 10px;
  background-color: #dff1ff;
  outline: none !important;
  width: 100%;
  box-shadow: none !important;
}

textarea:focus-visible {
  outline: none;
}


.comman_form .form-select:disabled {
  background-color: #DFF1FF;
}

.multi_services .searchWrapper input {
  padding: 0px;
  margin-top: 0px;
}

.quick_btn:hover,
.quick_btn {
  background-color: #DBA11C;
  display: flex;
  gap: 10px;
  margin: auto;
  justify-content: center;
  width: fit-content;
  border: none;
  /* padding: 10px; */
}

.blog_section .card {
  border: none;
  background: none;
}

.blog_section .card-body {
  display: flex;
  gap: 20px;
}

.blog_section .card-body .card-img,
.block_card .card-img-top {
  width: 50%;
  height: 300px;
  border-radius: 0;
}

.blog_section .card-body .card_content {
  width: 75%;
  text-align: left;
  /* height: 200px; */
  position: relative;
}

.blogs_ .card {
  border: none;
  margin-top: 40px;
}

.service_div .card {
  border-radius: 0px;
  margin-bottom: 30px;
  min-height: 18rem;
}




.img-subDiv .inner-btn {
  display: flex;
  gap: 5px;
  padding: 10px;
  justify-content: space-evenly;
}


.inner-btn button {
  padding: 6px 6px;
  font-size: 10px;

}

._success_d {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

._success_d .card {
  height: fit-content;
  border-radius: 0px;
}

._success_d .card h5 {
  color: var(--primary-clr);
}

._success_d .card span {
  font-weight: 500;
}

.con2 .card {
  position: relative;
  left: 20px;
}

.continue_reading button {
  border: 2px solid var(--primary-clr);
  background: transparent;
  color: var(--primary-clr);
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: auto;

}

.staps {
  height: 200px;
  display: flex;
  justify-content: center;
  gap: 40px;
  text-align: center;
}

.staps .singleStap span {
  background-color: #DBA11C;
  font-weight: bold;
  font-size: 30px;
  border-radius: 50%;
  padding: 30px;
  position: relative;
}

.staps .singleStap span::after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background: #DBA11C;
  right: 0;
  top: 50%;
  position: absolute;
}

.staps .singleStap p {
  margin-top: 30px;
  font-weight: bold;
  font-size: 20px;
}


.content_div h3 {
  /* font-size: 3.1rem; */
  margin-bottom: 2rem;
}

.chip {
  border-radius: 5px;
  margin-bottom: 0px !important;
  background-color: var(--primary-clr) !important;
}

.myProgress {
  width: fit-content;
  border: 1px solid lightgray;
  /* border-radius: 30px; */
  border-radius: 0;
  /* width: 50%; */
  font-weight: 600;
}

.myStaps span {
  /* width: 70px !important; */
  /* height: 70px !important; */
  top: 50% !important;
  /* border-radius: 50% !important; */
  color: black !important;
  font-size: var(--secondary-h);
  /* background-color: var(--secondary-clr); */
}



.myStaps .stapLable {
  color: black;
  width: 100%;
}

.myStaps::before {
  background-image: linear-gradient(to left, #dba11c50 50%, #DBA11C 50%) !important;
  width: 64% !important;
  right: 68% !important;
}

._2JvrO,
._2Jtxm ._2JvrO,
._2Jtxm._35Ago ._2kL0S {
  background-color: #DBA11C !important;
}

._2Jtxm ._2kL0S {
  background-color: #dba11c50 !important;
}

.content_ {
  padding: 20px 0px !important;
}

.content_ .leftDiv {
  text-align: left;
}

.content_ .leftDiv .input_div {
  display: flex;
  justify-content: space-between;
}



.content_ .leftDiv input,
.content_ .leftDiv textarea,
.content_ .leftDiv select {
  background-color: #dff1ff;
  border: 1px solid #bce1ff;
  outline: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 0px;
  width: 100%;
  font-weight: normal;
}

.details_ ul {
  row-gap: 30px;
  padding: 0.5rem 0rem;
  /* display: grid;
  gap: 10px; */
}

.details_ li {
  /* display: flex;
  justify-content: space-between; */

}

.details_ .form-check input.form-check-input {
  width: auto;
  background-color: #DFF1FF;
  cursor: pointer;
}

.details_ .form-check label.form-check-label {
  font-weight: normal !important;
  font-size: 16px;
  cursor: pointer;
}

.details_ h4.s_content_h {
  color: #146FB7;
  font-weight: bold;
  font-size: 1.75rem;
}

.details_ li .detail_content {
  display: flex;
  align-items: center;
  /* width: 50%; */
}

.detail_content h4.blue_h {
  color: var(--primary-clr);
  font-size: 18px;
}

.detail_content h4.blue_h .cool_icon_ {
  margin-right: 5px;
}

.leftDiv h4 {
  font-size: var(--third-h);
  padding-bottom: 0.5rem;
  font-weight: normal;
}

.details_ li .btn_div {
  display: flex;
  /* width: 50%; */
  align-items: center;
  justify-content: flex-end;
  /* width: 30%; */
  /* gap: 2px; */
}

.details_ li .btn_div._hour_ {
  width: 30%;
}

.btn_div span {
  justify-content: center;
  align-items: center;
  display: flex;
  /* color: var(--primary-clr); */
  /* padding: 0px 30px; */
  /* border: 1px solid var(--primary-clr); */
  /* background-color: #dff1ff; */
  font-weight: bold;
  font-size: var(--third-h);
  border-radius: 0px;
  /* height: 100%; */

}


._uniq_c {
  color: var(--primary-clr);
  font-weight: normal;
}

/* mui steps */
.MuiStepLabel-alternativeLabel {
  font-weight: bold !important;
  font-size: 16px !important;
  margin-top: 10px !important;
}


.content_ .MuiSvgIcon-root {
  font-size: 30px !important;
  /* color: #dba11c50 !important; */
  color: #fff !important;
}

.content_ svg.Mui-active,
.content_ svg.Mui-completed {
  /* color: #DBA11C !important; */
  /* font-size: 60px !important; */
}

.Mui-active .MuiStepIcon-text {
  color: var(--primary-clr) !important;
  fill: var(--primary-clr) !important;
}



.MuiStepLabel-label {
  /* padding: 10px 20px; */
  /* border-radius: 30px; */
  font-size: var(--third-h) !important;
}

.Mui-completed .MuiStepLabel-label {
  color: #fff !important;
}




.mySteps {
  padding-left: 0 !important;
  padding-right: 0 !important;
}


.myStaps .Mui-completed,
.mySteps .Mui-active {
  color: #fff !important;
}

.myLabel_2,
.myLabel_1,
.myLabel_0 {
  padding: 10px 30px;
  background-color: var(--primary-clr);

}

.myLabel_0 {
  /* border-right: 1px solid lightgray; */
  /* border-radius: 30px; */
  border-radius: 0;
}

.myLabel_1 {
  border-right: 1px solid lightgray;
  /* border-radius: 0px 30px 30px 0px; */
  border-radius: 0;
}

.myLabel_2 {
  /* border-radius: 0px 30px 30px 0px; */
  border-radius: 0;
}


.MuiStepConnector-horizontal {
  display: none !important;
}


.Mui-disabled {
  background-color: transparent;
  /* color: var(--primary-clr); */
  /* border: 1px solid lightgray; */
}

.Mui-completed .myLabel_1 {
  border-right: 0px solid lightgray;
  border-radius: 0px;
}

.Mui-completed .myLabel_0 {
  /* border-right: 1px solid lightgray; */
  /* border-radius: 30px 0px 0px 30px; */
  border-radius: 0;
}

.Mui-completed .myLabel_2 {
  /* border-radius: 0px 30px 30px 0px; */
  border-radius: 0;
}



.Mui-disabled .MuiStepIcon-text {
  fill: gray;
}

.MuiStepConnector-root {
  width: 75%;
  position: absolute;
  left: -38% !important;
  top: 40% !important;
}



.MuiStepConnector-line {
  border-radius: 0px !important;
  border-top-style: solid;
  border-top-width: 3px !important;
}

.Mui-completed>.MuiStepConnector-line,
.Mui-active>.MuiStepConnector-line {
  border-color: #DBA11C !important;
}

.Mui-disabled .MuiStepConnector-line {
  border-color: #dba11c50 !important;
}



.stepButton {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 1.5rem;
}

.stepButton button:hover,
.stepButton button {
  color: var(--third-clr);
  background-color: var(--primary-clr);
  border: none;
}

.stepButton button.prev {
  background-color: var(--third-clr);
  color: var(--primary-clr);
  border: 1px solid var(--primary-clr);
}

.form-check input.form-check-input {
  background-color: #fff;
  border-radius: 50%;
  outline: none;
  box-shadow: none;
}

.form-check input.form-check-input:checked {
  background-color: var(--primary-clr);
  border-radius: 50%;
}

.form-check-label {
  padding-top: 3px;
}


.rightDiv hr {
  border-top: 2px solid var(--primary-clr);
}

.rightDiv {
  border-radius: 0;
  border: 1px solid #bce1ff;
  position: sticky;
  top: 0;
}

.totalAmont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0px 50px; */
}

.totalAmont span {
  font-weight: bold;
  font-size: 24px;
  color: #DBA11C;
}

.totalAmont h6 {
  font-size: 20px;
  font-weight: bold;
  color: var(--primary-clr);
}

.rightDiv .footerContnt {
  text-align: left;
  line-height: 1.5rem;
  /* color: var(--primary-clr); */
}

.wrapperBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  width: fit-content;
  gap: 20px;
}

._2RWmX {
  display: none;
}

.wrapperBtn .nextBtn:hover,
.wrapperBtn .nextBtn {
  text-decoration: none;
  background-color: var(--primary-clr);
  color: var(--third-clr);
  font-weight: bold;
  padding: 0.5rem 1rem
}

.wrapperBtn .previousBtn {
  text-decoration: none;
  font-weight: bold;
  color: var(--primary-clr);
  padding: 0.5rem 1rem
}

.date_time .date_ {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 10px;

}

.date_time .date_ h4,
.time_ h4 {
  font-size: 16px;
  font-weight: 500;
}

.date_ ._next_date {
  /* margin-left: 70px; */
}

.time_ {
  display: flex;
  gap: 10px;
  margin-top: 15px;
  align-items: center;
}

.secondStap .dateAndTime {
  display: flex;
  gap: 20px;
}


.gallery_imgs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

.gallery_imgs article img {
  max-width: 100%;
  width: 100%;
  transition: transform .8s;
  min-height: 270px;
  cursor: pointer;
}

#_date input {
  float: right;
  position: relative;

}

#_date input[type="date"]::-webkit-calendar-picker-indicator {
  background-position: left;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
}



#_date input {
  padding: 10px 10px 10px 30px !important;
}

#_date input:disabled {
  padding: 10px !important;
}



._timeset_ {
  position: relative;
}

input[type="time"]._timeset_::-webkit-calendar-picker-indicator {
  background-position: right;
  background-size: auto;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 10px;
  top: 0;
  width: auto;
  height: auto;
}

.close_btn {
  position: absolute;
  top: 5%;
  right: 5%;
  color: var(--third-clr);
  font-size: 20px;
  cursor: pointer;
}

/* .modal.show .modal-dialog {
  max-width: 42%;
} */

.card_content .cardHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: black;
  background: transparent;
}

.card_content .cardHeader p {
  margin: 0px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.card_content a {
  color: var(--primary-clr);
  position: absolute;
  /* bottom: 0px; */
  font-weight: bold;
  text-decoration: underline;
}

.card_content p {
  /* font-size: 13px; */
  /* font-weight: 500; */
}

.blogs_ .cardHeader {
  display: flex;
  justify-content: space-between;
}


.blogs_ .card img {
  border-radius: 0px;
  /* width: 377px; */
  height: 223px;
  /* width: 350px;
  margin: auto; */
  /* height: 300px; */
}

.blogs_ .card .card-body {
  padding: 20px 0px;
}

.book_appointment-m {
  padding: 20px;
  /* text-align: center; */
  /* justify-content: center; */
  display: grid;
  gap: 20px;
  border-radius: 10px;
}

.book_appointment-m>button {
  width: fit-content;
  margin: auto;
}

.err_msg {
  color: red;
  font-size: 14px;
}


.spinner_d {
  color: var(--secondary-clr);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.mySpinner {
  position: fixed;
  top: 50%;
  left: 50%;
}

.leftImg {
  width: 50%;
  height: 200px;
}

.leftImg img {
  border-radius: 0px;
}

.img-subDiv ._ser_icon {
  background-color: #DBA11C;
}

.extraItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
  grid-gap: 11px;
  align-items: stretch;
  text-align: center;
}

.extras_d {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  position: relative;
  /* width: 25%; */
}

.extras_d .form-check {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.extras_d input {
  margin: auto;
  height: 50px;
  width: 50px !important;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: none !important;
  cursor: pointer;

}

.extras_d span {
  font-weight: bold;
  cursor: pointer;
  font-size: 10px;
}

:checked+label .check_icon {
  position: absolute;
  left: 10px;
  top: -6px;
  font-size: 20px;
  color: var(--primary-clr);
  display: block;

}

.booking_table .table-borderless>:not(caption)>*>* {
  padding: 0.5rem 0rem;
}

.booking_table th {
  font-weight: 500;
}

.check_icon {
  display: none;
}

.extras_d input:checked+label {
  border: 1px solid var(--primary-clr);
}

.select_multi .optionListContainer .optionContainer {
  display: grid;
  gap: 1px;
}

.select_multi {
  /* width: 80% !important; */
  background-color: #dff1ff;
}

.select_multi .searchWrapper {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  border-radius: 0px;
  border-color: #bce1ff;

}

.select_multi ul {
  list-style-type: none;
  padding: 0px !important;
}

.select_multi .searchWrapper input {
  padding: 0px;
  border: none;
  background: transparent;
  margin-top: 0px;
}

/* .optionContainer::-webkit-scrollbar-track, */
.select_multi .searchWrapper::-webkit-scrollbar-track {
  background-color: #bce1ff;
}

/* .optionContainer::-webkit-scrollbar, */
.select_multi .searchWrapper::-webkit-scrollbar {
  height: 5px;
  background-color: #bce1ff;
}

/* .optionContainer::-webkit-scrollbar-thumb, */
.select_multi .searchWrapper::-webkit-scrollbar-thumb {
  background-color: var(--primary-clr);
  border-radius: 5px;
}

.service_tabs_s ul.nav-tabs.service_tabs,
.our_profationals ul.nav-tabs {
  width: 70%;
  margin: auto;
  gap: 50px;
  flex-wrap: nowrap;
}

.service_tabs_s ul.nav-tabs.service_tabs>.nav-item,
.our_profationals .nav-tabs>.nav-item {
  border: 1px solid #DFF1FF;
}

.service_tabs_s .nav-fill .nav-item .nav-link,
.our_profationals .nav-fill .nav-item .nav-link {
  padding: 20px 0px;
  border-radius: 0px;
  border: none;
  color: var(--primary-clr);
  font-weight: bold;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.service_tabs_s .nav-fill .nav-item .nav-link {
  padding: 20px 20px;
}

.service_tabs_s .nav-tabs .nav-link.active,
.our_profationals .nav-tabs .nav-link.active {
  /* background-color: #DBA11C; */
  background-color: #146FB7;
  color: var(--third-clr);

}

.nav-link.active .h_,
.nav-link .h_w,
.nav-link.active .f_,
.nav-link .f_w {
  display: none;
}

.nav-link.active .h_w,
.nav-link.active .f_w {
  display: block;
}


.scrollTop {
  position: fixed;
  /* background-image: linear-gradient(#164f77 , #f2faff ); */
  background-color: #DBA11C !important;
  border: none;
  padding: 6px 12px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  right: 24px;
  text-align: 'center';
  transition: background-image .3s, opacity .5s, visibility .5s;
  opacity: 0;
  transform: translate(100px, 50px);
  transition: 0.5s;
  z-index: 1000;
  cursor: pointer;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.scrollTop:active,
.scrollTop:hover {
  background-color: #DBA11C;
}

.show_btn {
  opacity: 1;
  visibility: visible;
  transform: translate(10px, 10px);
  transition: 0.5s;
}

.QUICK_QUOTE {

  rotate: 270deg;
}


._model_btn {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
}

.center_i {
  width: 75%;
  height: auto;
  z-index: -1;
  margin: auto auto auto 5%;

}

.center_i img {
  margin: 50px 75px;

}

.form-d .call_d {
  /* width: 440px; */
  width: 40%;
  height: 400px;
  display: flex;
}

.form-d {
  /* position: absolute;
  left: 55%;
  bottom: 0;
  transform: translate(0%, 0%); */
  position: relative;
  display: flex;
  justify-content: end;
  /* margin-right: 15%; */
  margin-top: -300px;

}

.form-d .call_d h2 {
  font-weight: bold;
  padding: var(--btn-padding);
}

.banner-d {
  /* background-color: rgba(0, 0, 0, 0.461); */
  height: 100vh;
  width: 100%;
  display: flex;
  /* column-gap: 20px; */
  justify-content: space-between;
}


.line-in-middle {
  width: 60%;
  /* height: 400px; */
  background: linear-gradient(to right,
      transparent 0%,
      transparent calc(50% - 0.81px),
      var(--primary-clr) calc(50% - 0.8px),
      var(--primary-clr) calc(50% + 0.8px),
      transparent calc(50% + 0.81px),
      transparent 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 30px;
}

.line-in-middle img {
  border: 1px solid var(--primary-clr);
  border-radius: 50%;
  width: 75%;

}

.steps_div {
  display: flex;
  align-items: center;
  width: 100%;
}

.steps_div p {
  padding: 10px;
  border-radius: 5px;
  text-align: left;
}

.steps_div p:hover {
  background-color: var(--primary-clr);
  color: var(--third-clr);
}

.end_tenency img {
  width: 75%;
}

@media only screen and (max-width : 991.98px) {


  .card_content a {
    color: var(--primary-clr);
    position: absolute;
    /* bottom: 0px; */
    font-weight: bold;
    text-decoration: underline;
  }

  .after-figure:hover h3,
  .before-figure:hover h3 {
    left: 25%;
  }

  .card_content .cardHeader p {
    gap: 0;
  }

  .content_div {
    width: 100%;
  }

  .content_div h1 {
    font-size: 3rem;
  }

  .our_services {
    /* height: 690px; */
  }

  .serviceImgDiv {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .blogs_ .card img {
    width: 100%;
    height: auto;
  }

  .serviceSwiper .card {
    min-width: auto;
  }

  .form-d .call_d {
    /* width: 340px; */
    /* width: 50%; */
    height: 240px;

  }

  .center_i {
    /* width: 60%; */
  }

  .form-d {
    margin-top: -160px;
    /* margin-right: 10%; */
  }

  .center_i img {
    margin: 30px 55px;

  }

  .form-d .call_d h2 {
    font-size: var(--third-h);
  }

  .line-in-middle img,
  .line-in-middle {
    width: 100%;
  }

  .myLabel_2,
  .myLabel_1,
  .myLabel_0 {
    white-space: nowrap;
  }

  .MuiStepLabel-iconContainer {
    padding-right: 0px !important;
  }

}



button .comment {
  display: none;
}

button:hover .replies {
  display: none;
}

.card:hover .inner-div {
  display: inline;
}

.card .inner-div {
  display: none
}

.card:hover .iconImg {
  background-color: var(--primary-clr);
}


.appointment_btn_yellow:active {
  background-color: var(--secondary-clr) !important;
  color: var(--third-clr) !important;
  border-color: var(--secondary-clr) !important;
}

.img-subDiv .appointment_btn_yellow:hover {
  background-color: white;
  color: #DBA11C;
  border-radius: 0px;
}

.appointment_btn_yellow a {
  color: var(--third-clr);
}

.card:hover .img-subDiv>span {
  padding: 5px 0px !important;
}

.card:hover .img-subDiv._service_div span {
  padding: 14px 30px !important;
  /* padding: 14px 30px; */
}

.myProgress path[stroke] {
  stroke: black !important;
  stroke-width: 3px;
}

.img-subDiv._service_div span {
  /* padding: 20px; */
  padding: 14px 30px;
}

.blogs_ .continue {
  color: var(--primary-clr);
  position: absolute;
  bottom: 0px;
  font-weight: bold;
  text-decoration: underline;
}

._single_date {
  margin-left: 30px;
}

.learnMore button {
  color: var(--primary-clr);
  background-color: white;
  bottom: 0px;
  padding: 0px 20px;
  font-weight: bold;
  margin-top: 25px;
  line-height: 3;
  border-radius: 10px;
  border: 1px solid #dff1ff;
}

.learnMore button a {
  padding: 15px;
  color: var(--primary-clr);
}



.btn.disabled,
.btn:disabled,
fieldset:disabled .btn,
._counter_btn button:disabled {
  background-color: gray !important;
  border-right: 1px solid var(--primary-clr) !important;
}



._counter_btn button {
  border: none;
  padding: 8px;
  width: 10%;
}

._counter_btn span {
  /* border: 1px solid var(--primary-clr); */
  border: none;
  /* background-color: #DFF1FF; */
  border-radius: var(--btn-radius);
  font-size: var(--secondary-h);
  width: 80%;
  font-size: var(--third-h);
  font-weight: normal;
}

._counter_btn {
  gap: 0px;
  border: 1px solid var(--primary-clr);
  /* width: 30% !important; */
}

.css-prp {
  color: var(--primary-clr);
  font-family: 'Work Sans', sans-serif;
}



.con1 {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

}


.con1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}


.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #DFF1FF;
  border-radius: 10%;
}

.con1 input:checked~.checkmark {
  background-color: var(--primary-clr);
}

._select_square span {
  padding: 3px 17px;
}

._counter_btn button {
  background-color: var(--primary-clr);
}

._counter_btn button:focus {
  background-color: var(--primary-clr);
}

._counter_btn button:hover {
  background-color: var(--primary-clr);
}

._counter_btn .btn:focus-visible {
  background-color: var(--primary-clr);
}

.input_div button {
  background-color: var(--primary-clr);
}

.input_div button.btn:hover {
  background-color: var(--primary-clr);
}

.input_div button.btn:focus {
  background-color: var(--primary-clr);
}

.input_div button.btn:focus-visible {
  background-color: var(--primary-clr);
}

.secondStap .css-1s2u09g-control,
.css-1rhbuit-multiValue {
  background-color: #DFF1FF;
  border: 1px solid var(--primary-clr) !important;
}

.css-xb97g8:hover {
  background-color: #DFF1FF !important;
  border: none;
  color: var(--primary-clr) !important;
}

.css-xb97g8 {
  background-color: #DFF1FF !important;
  border: none;
  color: var(--primary-clr) !important;
}

.css-12jo7m5 {
  background-color: #DFF1FF !important;
  border: none;
  color: var(--primary-clr) !important;
}

.css-tlfecz-indicatorContainer .css-tj5bde-Svg {
  color: var(--primary-clr);
}

.con2 {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.con2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.con2 input:checked~.checkmark1 {
  background-color: var(--primary-clr);
}

._select_radio span {
  /* padding: 15px 12px; */
  padding: 12px;
}

._select_radio .extras_d label {
  padding: 20px;
  background-color: #dff1ff;
  border: 1px solid #bce1ff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

._select_radio .extras_d label>span {
  font-size: 15px;
}


ul.list-unstyled li>svg {
  font-size: 20px;
  color: var(--primary-clr);
  margin-left: -24px;
}

._select_radio .extras_d label svg {
  top: 4px;
}

.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #DFF1FF;
  border-radius: 10%;
}

._calendar_icon {
  /* background-color: var(--primary-clr); */
  padding: 5px;
  font-size: 30px;
  color: var(--primary-clr);
}

.thirdStap .leftDiv label {
  font-weight: 500;
  font-size: 16px;
}

.MuiBreadcrumbs-li>a {
  color: var(--primary-clr);
}

._select_square span {
  color: black;
  font-weight: 500;
  font-size: 16px;
  gap: 20px;
}


.checkmark1>.checkedIcon,
.checkmark>.checkedIcon {
  color: #DFF1FF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.dateAndTime>input {
  display: flex;
  flex-direction: row-reverse;
  /* width: 100% !important; */

}

.css-b62m3t-container {
  width: 80%;
}

/* success */

.main-container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.check-container .check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #5de593, #41d67c);
  box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  animation: animateContainer 0.75s ease-out forwards 0.75s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.check-container .check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
  animation: animateCheck 0.35s forwards 1.25s ease-out;
}

.check-container .check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, rgba(73, 218, 131, 1), transparent);
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  43.75% {
    transform: scale(1.15);
    box-shadow: 0px 0px 0px 43.334px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 65px rgba(255, 255, 255, 0.25) inset;
  }

  62.5% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 21.667px rgba(255, 255, 255, 0.25) inset;
  }

  81.25% {
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }

  100% {
    opacity: 1;
    box-shadow: 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset, 0px 0px 0px 0px rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }

  25% {
    opacity: 0.25;
  }

  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }

  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}


/* new style */


/* .third-img,
.second-img, */
.banner-img {
  position: relative;
  height: 100vh;
  width: 33.33%;

}

.banner-img:nth-child(2) {
  background: no-repeat top center fixed;
  background-attachment: fixed !important;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.banner-img button {
  opacity: 0;
  transition: all 0.5s ease;
  transform: translateX(-100px);
}

.banner-img:hover button {
  opacity: 1;
  transform: translateX(0px);
}



/* .third-img img,
.second-img img, */
.banner-img img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* .second-img img { */
/* position: relative;
  left: 50%;
  transform: translate(-50%, 0); */
/* } */

.content-s {
  position: relative;
  /* height: 100vh */
  /* min-height: 800px; */
}

.content-s .content-d {
  text-align: center;
  color: var(--third-clr);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.content-d h1 {
  color: var(--third-clr);
  font-size: 30px;
  /* text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
}

.content-d h2 {
  font-size: var(--primary-h);
  color: var(--third-clr);
  /* text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
    0px -4px 10px rgba(255,255,255,0.3); */
}

.content_div button.call-btn,
.continue_reading button.appoinment-btn,
.our-company button.appoinment-btn {
  border-radius: var(--btn-radius);
  padding: var(--btn-padding);
  border: var(--btn-border) var(--primary-clr);
  background: transparent;
  color: var(--primary-clr);
  font-weight: bold;
  font-size: var(--third-h);
  /* transition: all 0.5s; */
}

.content_div button.call-btn:hover,
.continue_reading button.appoinment-btn:hover,
.our-company button.appoinment-btn:hover {
  background-color: var(--primary-clr);
  color: var(--third-clr);
  border-color: var(--primary-clr);
}

.call-btn a {
  color: var(--primary-clr);
  transition: all 0.5s ease;
}

.call-btn:hover a {
  color: var(--third-clr);
}

.content-d button {
  background: transparent;
  border: none;
  color: #fff;

}

.content-d button:active,
.content-d button:hover {
  background: transparent !important;
}


.img-s {
  display: flex;
  gap: 30px;


}

.img-s a {
  width: 33.33%;
}

.img-s img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: 50% 50%;
}



.grid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: 150px;
  grid-auto-flow: row dense;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  background: var(--gray-clr);
  color: var(--third-clr);
  grid-column-start: auto;
  grid-row-start: auto;
  color: var(--third-clr);
  background-size: cover;
  background-position: center;
  box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4);
  /* transition: transform 0.3s ease-in-out; */
  cursor: pointer;
  counter-increment: item-counter;
  z-index: 1;
}

.item:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}


.item--medium {
  grid-row-end: span 2;
}


@media screen and (min-width: 768px) {
  .item--full {
    grid-column: -1;
    grid-row-end: span 2;
  }
}

.item__details {
  position: relative;
  z-index: 1;
  padding: 15px;
  background: var(--third-clr);
  text-transform: capitalize;
  /* letter-spacing: 1px; */
  color: var(--gray-clr);
  font-weight: bold;
  font-size: var(--third-h);
  text-align: center;
}



.blog .blog_title {
  margin: 0px 0px 30px;
  color: #666;
  font-family: 'Work Sans', sans-serif;
}

.blog .blog_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: center;
  width: 100%;
  justify-content: center;
}

.blog .blog_wrapper .blog_item {
  margin: 0px 20px 40px;
  min-height: 300px;
  background: var(--third-clr) 50%;
  background-size: cover;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 8px 14px 38px rgba(39, 44, 49, .06), 1px 3px 8px rgba(39, 44, 49, .03);
  transition: all 0.5s ease;
}

.blog .blog_wrapper .blog_item a {
  color: inherit;
  /* blue colors for links too */
  text-decoration: inherit;
}

.blog .blog_wrapper .blog_item .bi_img {
  height: 200px;
  margin: 0px 0px 20px;
  overflow: hidden;
}

.blog .blog_wrapper .blog_item .bi_img img {
  width: 100%;
}

.blog .blog_wrapper .blog_item .bi_title {
  font-size: var(--third-h);
  font-weight: bold;
  margin: 1em 0 0.5em;
  color: var(--gray-clr);
}

.blog .blog_wrapper .blog_item .bi_content {
  padding: 16px;
  font-size: var(--third-h);
  text-align: justify;
  color: var(--black-clr);
}

.blog .blog_wrapper .blog_item .bi_content p {
  margin: 0;
}


.blog .blog_wrapper .blog_item .reading_time {
  font-family: 'Work Sans', sans-serif;
  color: var(--primary-clr);
  font-size: var(--third-h);
  padding: 16px;
  line-height: 33px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  text-align: right;
}

.blog .blog_wrapper .blog_item:hover {
  box-shadow: 8px 28px 50px rgba(39, 44, 49, .07), 1px 6px 12px rgba(39, 44, 49, .04);

  transition: all 0.4s ease;
  transform: translate3D(0, -1px, 0) scale(1.02);
}

.blog .blog_button {
  grid-column: -4/-1;
}

.blog_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog .blog_button a {
  text-decoration: inherit;
  display: block;
  width: fit-content;
  margin: 0px auto 24px;
  letter-spacing: 1px;
  padding: var(--btn-padding);
  background: transparent;
  font-weight: bold;
  font-size: var(--third-h);
  color: var(--primary-clr);
  border: var(--btn-border);
  border-radius: var(--btn-radius);
  border-color: var(--primary-clr);
  outline-offset: -2px;
  transition: all 0.5s ease;
  text-align: center;
  margin-bottom: 0px;

}

.blog .blog_button a:hover {
  background-color: var(--primary-clr);
  color: var(--third-clr);
}


.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}

.gallery,
.gallery-item {
  margin: 0;
}

@media only screen and (max-width: 900px) {

  .gallery,
  .blog .blog_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {

  .gallery,
  .blog .blog_wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }
}


/* review */

.marque-s {
  --space: 3rem;

  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--space);
  width: 100%;
  /* font-size: 1.5rem; */
  line-height: 1.5;
}

.marquee {
  --duration: 40s;
  --gap: var(--space);
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;

}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group h4 {
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid #ccc;
  padding: 3rem;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

/* review */

.gallery-item {
  flex: 1 0 24rem;
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.gallery-image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 400ms ease-out;
}

.gallery-image:hover {
  transform: scale(1.15);
}


.about_service p {
  line-height: 2rem;
}

.continue_reading-d {
  margin: var(--padding-equal) 0rem;
}


/* video  */
#video_ {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.editor_d ol,
.editor_d ul {
  list-style-type: disclosure-closed;
  padding-left: 20px;
}

._select_radio .extras_d label img {
  width: 50px;
  height: 50px;
}

._select_radio .extras_d label img:nth-of-type(3) {
  width: 50px;
  height: 45px;
}


@media only screen and (max-width : 767.98px) {

  .our-company ._heading {
    width: 100%;
  }

  .our_profationals .nav-fill .nav-item .nav-link {
    gap: 4px;
  }

  .btn {
    font-size: var(--m-third-h);
  }

  .banner-d {
    height: 100%;
    flex-direction: column;
  }

  .banner-d .banner-img {
    width: 100%;
  }

  .card_content a {
    color: var(--primary-clr);
    position: absolute;
    /* bottom: 0px; */
    font-weight: bold;
    text-decoration: underline;
  }

  .banner {
    min-height: 490px;
    /* height: 600px; */
  }

  .content_div h3,
  .content_div h1 {
    font-size: 2.25rem;
  }

  .bookAppoinment_section {
    flex-direction: column;
    height: auto !important;
  }

  .tab_content {
    flex-direction: column;
    height: auto !important;
    gap: 40px;

  }


  .center_imgDiv img {
    width: 100%;
  }

  .our-company img {
    float: right;
    width: 100%;
  }

  .content_div .strar-btn,
  .banner .call-btn {
    font-size: 14px;
    padding: 5px 16px;
  }



  .bookAppoinment_section .form-select {
    width: 100%;
  }

  .appoinment-btn:hover,
  .appoinment-btn:active,
  .appoinment-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--third-clr);
    background-color: #DBA11C;
    border-color: #DBA11C;
    margin: 0px !important;
    font-size: 13px;
    float: left;
  }

  .appoinment-btn>img {
    width: 18px !important;
  }



  .line-in-middle {
    width: 100%;
  }

  .our_process_div {
    flex-direction: column;
    position: relative;
  }

  .our_process_imgDiv>.img1 {
    position: absolute;
    top: -145px;
    width: 40%;
    /* left: -200px; */
  }

  .our_process_imgDiv>.img2 {
    position: absolute;
    bottom: 18px;
    /* bottom: 0; */
    width: 60%;
    right: 0px;
    /* top: 0; */
  }

  .our_process_imgDiv>.img3 {
    position: absolute;
    right: 0px;
    width: 40%;
    left: 58%;
  }

  .our_process_imgDiv>.img4 {
    display: none;
  }

  .bg_imgDiv .img2,
  .our_process_imgDiv>.img5 {
    display: none;
  }



  .bookUsNow .btn_div {
    gap: 20px;
  }

  .bookUsNow .btn_div button {
    font-size: 13px;
    gap: 10px;
    padding: 5px 10px;

  }

  .blogSwiper {
    height: auto;
  }

  .content_ .leftDiv input,
  .content_ .leftDiv textarea,
  .content_ .leftDiv select {
    width: 100%;
  }

  .blog_section .card-body {
    flex-direction: column;
  }

  .leftImg {
    width: 100%;
    height: auto;
  }

  .contact_us {
    flex-direction: column;
  }

  .left_div {
    padding: 0;
    padding-left: 40px;
  }

  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: 30% !important;
    top: 85% !important;
    background-color: var(--third-clr);
    padding: 30px !important;
    border-radius: 50%;
    width: 25px !important;

  }

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: 30% !important;
    top: 85% !important;
    background-color: var(--third-clr);
    padding: 30px !important;
    border-radius: 50%;

  }

  .end_tenency img {
    width: 100%;
    margin: 1rem;
  }

  .serviceSwiper .card {
    width: 75%;
    margin: auto;
    min-width: auto;

  }

  .content_div {
    width: 100%;
  }

  .content_div h1 {
    font-size: 2.5rem;
  }

  .content_div h2 {
    font-size: 1.25rem;
  }

  .blog_section .card-body .card_content {
    width: 100%;
  }

  .client_div {
    /* height: 475px; */
  }

  .our-company:nth-child(1) {
    /* width: 90%; */
  }


  .our-company {
    width: 100%;
  }

  .why_blueCleaning .nav-link {
    padding: 20px 0px;
  }

  .modal.show .modal-dialog {
    max-width: 100%;
  }


  .myLabel_2,
  .myLabel_1,
  .myLabel_0 {
    padding: 10px;
    flex-direction: column;
    text-align: center;
    white-space: normal;
  }




  .content_div .starDiv span {
    font-size: 12px;
  }

  .serviceImgDiv {
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }

  .block_card .card-img-top,
  .serviceImgDiv img {
    width: 100%;
  }

  .our_works .nav-tabs {
    width: 100%;
    gap: 10px;
  }

  .why_blueCleaning .nav-tabs {
    margin: 0px 20px;
  }

  .why_blueCleaning .nav-tabs .nav-link {
    font-size: 14px;
  }

  .serviceSwiper {
    flex-direction: column;
    gap: 40px;
    padding-bottom: 5rem;
    height: 100%;
  }

  ._success_d {
    grid-template-columns: 1fr;
  }



  .gallery_imgs {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));

  }

  .our_works .nav-fill .nav-item .nav-link,
  .our_works .nav-justified .nav-item .nav-link {
    font-size: 11px;
  }

  .center_i {
    width: 85%;
    margin: 0;
  }

  .center_i img {
    margin: 20px 30px;
  }

  .form-d .call_d {
    width: 50%;
    height: 200px;
  }


  .our_profationals ul.nav-tabs {
    width: 80%;
    margin: auto;
  }

  .our_profationals .nav-fill .nav-item .nav-link img {
    width: 34px;
  }

  ._select_radio .extras_d label {
    padding: 4px;
    flex-direction: column;
  }

  ._select_radio .extras_d label img {
    width: 45px;
    height: 45px;
  }

  ._select_radio span {
    /* padding: 15px 12px; */
    padding: 3px;
    font-size: 12px;
  }

}